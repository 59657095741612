import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";

import PAGES from "src/constants/pages";
import { LOCAL_STORAGE } from "src/constants/localStorage";
// eslint-disable-next-line camelcase
import { login_get } from "src/actions/loginAction";
import { utils } from "src/utils/utils_general";
import { ConsentFlowStep, GenericRegistrationFlowStep } from "./types";
import SignupConsent2 from "./SignupConsent/SignupConsent2";
import BlankComponent from "src/components/core/Blank/BlankComponent";

interface ConsentWrapperWithReduxActions extends PropsFromRedux {
  registrationFlow?: GenericRegistrationFlowStep[] | null;
}

type ConsentWrapperProps = ConsentWrapperWithReduxActions & RouteComponentProps;

// eslint-disable-next-line camelcase,no-shadow
function ConsentWrapper({
  registrationFlow,
  login_get,
  history,
}: ConsentWrapperProps) {
  const regFlow =
    registrationFlow ||
    utils.get_local_storage(LOCAL_STORAGE.REGISTRATION_FLOW);

  const [requiredConsentsArray, setRequiredConsentsArray] = useState<
    ConsentFlowStep[] | null
  >(null);

  const handleUpdateCurrentWorkFlow = (workflow: ConsentFlowStep[] | null) => {
    setRequiredConsentsArray(workflow);
  };

  const handleAllConsentsComplete = () => {
    goToNextStep();
  };

  async function goToNextStep() {
    await login_get();
    history.push(PAGES.DASHBOARD);
  }

  const getRequiredConsentCodes = async () => {
    const { consent_require: requiredConsents = [] } = await login_get();

    const stubConsentsArray: ConsentFlowStep[] = requiredConsents.map(
      (consentCode: string, index: number): ConsentFlowStep => ({
        id: "",
        app_feature: "consent",
        component: "consent",
        consent_code: consentCode,
        index,
        name: consentCode,
        step: "Consent",
        entry_attribute: ["*"],
        exit_attribute: ["*"],
        value: null,
      }),
    );

    setRequiredConsentsArray(stubConsentsArray);
  };

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      getRequiredConsentCodes();
    }

    return () => {
      isCancelled = true;
    };
  }, []);

  if (!requiredConsentsArray) {
    return <BlankComponent />;
  }

  return (
    <div>
      <SignupConsent2
        mandatoryConsentFlow={requiredConsentsArray}
        defaultFlow={regFlow}
        updateFlow={handleUpdateCurrentWorkFlow}
        onConsentCompleted={handleAllConsentsComplete}
        exitSignupSubworkflow={goToNextStep}
      />
    </div>
  );
}

const mapDispatchToProps = {
  login_get,
};
const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default withRouter(connector(ConsentWrapper));
