// file needs to be refactored/componentized
import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import mapboxgl from "mapbox-gl";
import { Button } from "react-bootstrap";

import { withTranslation } from "react-i18next";
import { CONFIG } from "../../constants/config";
import { mapboxConfig } from "src/constants/api_config";
import RGCLogo from "../../assets/images/logo.png";
// import {ReactComponent as ZoomOutIcon} from '../../assets/icons/zoom-out.svg';
import { utils } from "../../utils/utils_general";
import { ancestry_get } from "../../actions/ancestryAction";
import { showSpinner, hideSpinner } from "../../actions/spinnerAction";
import { latlng_get, getGeometry } from "../../actions/mapquestAction";
import { ReactComponent as AncestryLockIcon } from "../../assets/icons/ancestry-locked.svg";
import { ReactComponent as AncestryUserAgreeIcon } from "../../assets/icons/ancestry-user-agree.svg";
import { ReactComponent as AncestryUserMaybeLaterAgreeIcon } from "../../assets/icons/ancestry-user-maybe-later-agree.svg";
import { ReactComponent as LockIcon } from "src/assets/icons/lock-2.svg";
import SuggestedSurveys from "../dashboard/suggested_surveys";
import { ModalReferralBanner } from "../referral/modal-referral-banner";
import PAGES from "src/constants/pages";
import AncestryMessage from "../global/ancestry_message";
import { workflow_get } from "../../actions/workflowAction";
import { utils_workflow } from "../../utils/utils_workflow";
import HtmlContentComponent from "src/components/core/HtmlContent/HtmlContentComponent";

import "mapbox-gl/dist/mapbox-gl.css";
import AncestrySubcompRenderer from "./ancestry_subcomp_renderer";
import { LOCAL_STORAGE } from "src/constants/localStorage";
import { post_user_attributes_post } from "src/actions/userAction";
import { ATTRIBUTES } from "src/constants/attributes";
import { ANCESTRY } from "src/constants/ancestry";

import { groupBy } from "lodash";
import Footer from "../global/footer";

mapboxgl.accessToken = mapboxConfig.accessToken;
const MAPBOX_STYLE = mapboxConfig.styleUrl;
const isMobile = utils.is_mobile();
const DEFAULT_ZOOM = isMobile ? 1 : 2;

export const Swipeable = ({ children, ...props }) => {
  const handlers = useSwipeable(props);
  return <div {...handlers}>{children}</div>;
};

class Ancestry extends React.Component {
  constructor(props) {
    super(props);
    this.tablePositions = ["closed", "centered", "opened"];
    const paddingBottomMobileInPx = window.innerHeight * 0.45; // same percentage that .ancestry .ancestry-panel.centered
    const polygonPaddingMobile = {
      top: 10,
      bottom: paddingBottomMobileInPx,
      left: 10,
      right: 10,
    };
    const polygonPaddingDesktop = { top: 20, bottom: 20, left: 400, right: 20 };
    this.mapMovementOptions = {
      padding: isMobile ? polygonPaddingMobile : polygonPaddingDesktop,
      speed: 1.5,
      curve: 1,
      essential: true,
      easing(t) {
        return t;
      },
    };
    this.state = {
      zoom: DEFAULT_ZOOM,
      numLevels: 0,
      ancestry: null,
      ancestryUnlocked: null,
      map: null,
      currentLevel: null,
      loading: false,
      selectedRegion: {},
      levelsBbox: {},
      gatingLevel: null,
      ancestry_agreement_panel: false,
      ancestry_user_agree_later: false,
      mapLayers: {},
      ancestry_components: null,
      errors: {},
      levelLoaded: 0,
      waitingMapLoading: false,
      mobileTablePosition: this.tablePositions[1],
      isMapReady: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.setState({ loading: true });
  }

  componentDidMount() {
    const ANCESTRY_AGREEMENT_LATER =
      utils.get_local_storage(LOCAL_STORAGE.ANCESTRY_AGREEMENT_LATER) === true
        ? true
        : false;
    this.setState({ ancestry_user_agree_later: ANCESTRY_AGREEMENT_LATER });
    this.getAncestry();
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {};
  }

  getAncestry() {
    this.props
      .ancestry_get()
      .then((res) => {
        if (res[0].data && res[0].status && res[0].status === "block") {
          this.setUpMap(null, 0);
          this.setState({ gatingLevel: 0, loading: false });
        } else {
          const levelsBlocked = res.filter(
            (level) => level?.status === "block",
          );
          const gatingLevel =
            levelsBlocked.length <= 0 ? 3 : levelsBlocked[0].level - 1;
          let ancestry = res.map((level) => level.data);
          let ancestryUnlocked = res
            .filter((level) => !level.status)
            .map((level) => level.data);
          ancestryUnlocked = this.rectifyAncestryLevels(ancestryUnlocked);
          ancestry = this.rectifyAncestryLevels(ancestry);
          this.setState({
            ancestryUnlocked,
            ancestry,
            loading: false,
            gatingLevel,
            currentLevel: 1,
            numLevels: res.length - 1,
          });
          this.setUpMap(ancestryUnlocked, gatingLevel);
        }
      })
      .catch((error) => {
        this.setUpMap(null, -1);
        return error?.response?.status === 423
          ? this.setState({
              gatingLevel: -1,
              loading: false,
              ancestry_agreement_panel: true,
            })
          : this.setState({
              gatingLevel: -1,
              loading: false,
              ancestry_agreement_panel: false,
            });
      });

    // we need this in order to lock the user out of ancestry when there is an enforced survey...
    if (!this.props.workflow) {
      this.getDashboardWorkflow();
    } else {
      utils_workflow.checkDashboardWorkflow(
        this.props.workflow,
        this.props.userAttribute,
        this.props.history.push,
        PAGES.ANCESTRY,
      );
    }
  }

  getDashboardWorkflow() {
    this.props.workflow_get().then((resp) => {
      utils_workflow.checkDashboardWorkflow(
        resp,
        this.props.userAttribute,
        this.props.history.push,
        PAGES.ANCESTRY,
      );
    });
  }

  setUpMap(ancestryData, gatingLevel) {
    const map = new mapboxgl.Map({
      container: this.mapContainer,
      style: MAPBOX_STYLE,
      zoom: this.state.zoom,
      minZoom: 1,
      maxZoom: 7,
      dragRotate: false,
    });
    map.resize();
    map.on("zoom", () => {
      const currentZoom = Math.round(map.getZoom());
      this.setState({ zoom: currentZoom });
    });
    this.loadMap(map, ancestryData, gatingLevel);
    this.setState({ map });
  }

  loadMap(map, ancestryData, gatingLevel) {
    if (gatingLevel > 0) {
      map.on("load", () => {
        this.loadLayer(ancestryData[0], 1);
        if (gatingLevel > 1) {
          this.loadLayer(ancestryData[1], 2);
        }
        if (gatingLevel > 2) {
          this.loadLayer(ancestryData[2], 3);
        }
        this.setState({ isMapReady: true });
      });
    }
  }

  rectifyAncestryLevels(ancestry) {
    let data = [...ancestry];
    if (ancestry.length > 1) {
      const regionCodes = {};
      data = ancestry.map((regions, index) => {
        return regions?.filter((region) => {
          if (index === 0) {
            regionCodes[region.code] = true;
            return true;
          } else {
            if (regionCodes[region.parent_code]) {
              regionCodes[region.code] = true;
              return true;
            } else {
              return false;
            }
          }
        });
      });
    }
    return data;
  }

  loadCoordsOnMap(geometry, properties, levelCode, layerVisible, levelClicked) {
    const { map } = this.state;
    this.setCoordOnMap(
      map,
      levelCode,
      properties,
      geometry.coordinates,
      0,
      layerVisible,
      levelClicked.code,
    );
    return 1; // num of layers
  }

  /** Executed when all the regions of each level are obtained from the backend  */
  levelFullyCharged(level, mapLayersList, layerVisible, levelClicked) {
    const {
      map,
      currentLevel,
      waitingMapLoading,
      selectedRegion,
      levelLoaded,
    } = this.state;
    if (layerVisible) {
      // if the level is the one visible
      this.orderLayers(map, mapLayersList, levelClicked.code);
      this.setMapCenter();
      this.props.hideSpinner();
    } else if (waitingMapLoading && levelLoaded === currentLevel) {
      // if the user clicks on the table before loading the level, when the level is loaded we click on the region manually
      this.onRegionClick(selectedRegion, level, true);
      this.props.hideSpinner();
    }
  }

  getDataOrigin(regionCode, level1Geometry) {
    return level1Geometry[regionCode]
      ? Promise.resolve(level1Geometry[regionCode])
      : this.props.getGeometry(regionCode);
  }

  loadLayer(regions, level, levelClicked = {}) {
    const { mapLayers, currentLevel, levelsBbox } = this.state;
    const layerVisible = level === currentLevel;
    levelsBbox[level] = [];
    const mapLayersList = [];
    const toLocalStorage = {};
    let requestFinished = 0;
    this.showMapSpinner();
    const level1Geometry = {};
    for (let i = 0; i < regions.length; i++) {
      const regionCode = regions[i].code;
      let promise = this.getDataOrigin(regionCode, level1Geometry);
      promise
        .then((region) => {
          requestFinished++;
          const { properties, geometry, extension, area } =
            region?.features?.[0];
          toLocalStorage[regionCode] = region;
          const layers = this.loadCoordsOnMap(
            geometry,
            properties,
            regionCode,
            layerVisible,
            levelClicked,
          );
          const layerData = {
            code: regionCode,
            area,
            extension,
            layers,
          };
          levelsBbox[level].push(extension?.bbox);
          mapLayers[regions[i].code] = layerData;
          mapLayersList.push(layerData);
          if (requestFinished === regions.length) {
            this.setState(
              {
                mapLayers,
                levelsBbox,
                levelLoaded: this.state.levelLoaded + 1,
              },
              () => {
                this.levelFullyCharged(
                  level,
                  mapLayersList,
                  layerVisible,
                  levelClicked,
                );
              },
            );
          }
        })
        .catch((error) => {
          requestFinished++;
          if (requestFinished === regions.length) {
            this.setState(
              {
                mapLayers,
                levelsBbox,
                levelLoaded: this.state.levelLoaded + 1,
              },
              () => {
                this.levelFullyCharged(
                  level,
                  mapLayersList,
                  layerVisible,
                  levelClicked,
                );
              },
            );
          }
          console.error("Error loading layer: ", error);
        });
    }
  }

  orderLayers(map, mapLayers, firstLayerCode) {
    mapLayers.sort((a, b) => Number(a.area) - Number(b.area));
    if (firstLayerCode) {
      const layerIndex = mapLayers.findIndex(
        (layer) => layer.code === firstLayerCode,
      );
      mapLayers = utils.moveArrayElementToTop(mapLayers, layerIndex);
    }
    mapLayers.forEach((layer, indexLayer) => {
      if (indexLayer < mapLayers.length - 1) {
        const nextLayer = indexLayer + 1;
        for (let index = 0; index < mapLayers[nextLayer].layers; index++) {
          map.moveLayer(
            `${mapLayers[nextLayer].code}.${index}-layer`,
            `${layer.code}.0-layer`,
          );
        }
      }
    });
  }

  setCoordOnMap(
    map,
    level,
    properties,
    polygon,
    index,
    visible,
    levelCodeClicked,
  ) {
    try {
      const {
        name,
        fill,
        "fill-opacity": fillOpacity,
        "stroke-opacity": strokeOpacity,
        stroke,
      } = properties;
      const strokeLines =
        strokeOpacity === 1 ? { "fill-outline-color": stroke } : {};
      const opacity = levelCodeClicked
        ? levelCodeClicked === level
          ? fillOpacity
          : 0.1
        : fillOpacity;
      const polygonData = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {
              name,
            },
            geometry: {
              type: "Polygon",
              coordinates: polygon,
            },
          },
        ],
      };

      // Split geometries into separate features
      let newFeatures = [];
      polygonData.features.forEach((feature) => {
        feature.geometry.coordinates.forEach((coordinate) => {
          newFeatures.push({
            ...feature,
            geometry: {
              ...feature.geometry,
              coordinates: [coordinate],
            },
          });
        });
      });
      polygonData.features = newFeatures;

      map.addSource(`${level}.${index}`, {
        type: "geojson",
        data: polygonData,
      });

      map.addLayer(
        {
          id: `${level}.${index}-layer`,
          type: "fill",
          source: `${level}.${index}`,
          paint: {
            "fill-color": fill,
            "fill-opacity": opacity,
            ...strokeLines,
          },
          layout: {
            visibility: visible ? "visible" : "none",
          },
        },
        "state-label",
      );
    } catch (error) {
      console.error(`Error adding source layer (${level}): `, error);
    }
  }

  // get the outer limits of a list of bounding boxes, maximum points to move the map and cover all regions
  getOuterBoundaries(bounding_boxes) {
    let maxBounds = {
      minLng: Infinity,
      minLat: Infinity,
      maxLng: -Infinity,
      maxLat: -Infinity,
    };
    for (const bbox of bounding_boxes) {
      maxBounds.minLng = Math.min(maxBounds.minLng, bbox[0]);
      maxBounds.minLat = Math.min(maxBounds.minLat, bbox[1]);
      maxBounds.maxLng = Math.max(maxBounds.maxLng, bbox[2]);
      maxBounds.maxLat = Math.max(maxBounds.maxLat, bbox[3]);
    }
    return maxBounds;
  }

  setMapCenter() {
    const { map, levelsBbox, currentLevel } = this.state;
    if (levelsBbox[currentLevel].length > 0) {
      const maxBounds = this.getOuterBoundaries(levelsBbox[currentLevel]);
      map.fitBounds(
        [
          [maxBounds.minLng, maxBounds.minLat],
          [maxBounds.maxLng, maxBounds.maxLat],
        ],
        this.mapMovementOptions,
      );
    }
  }

  switchLayerLevel(level) {
    const { map, currentLevel, mapLayers } = this.state;
    for (const layerKey in mapLayers) {
      const layer = mapLayers[layerKey];
      if (level) {
        if (layer.code.includes(`L${level}.`)) {
          for (let index = 0; index < layer.layers; index++) {
            map.setLayoutProperty(
              `${layer.code}.${index}-layer`,
              "visibility",
              "visible",
            );
          }
        }
      }
      if (layer.code.includes(`L${currentLevel}.`)) {
        for (let index = 0; index < layer.layers; index++) {
          map.setLayoutProperty(
            `${layer.code}.${index}-layer`,
            "visibility",
            "none",
          );
        }
      }
    }
  }

  toggleLayerOpacity(level, levelCode) {
    const { map, mapLayers } = this.state;
    for (const layerKey in mapLayers) {
      const layer = mapLayers[layerKey];
      if (layer.code.includes(`L${level}.`)) {
        for (let index = 0; index < layer.layers; index++) {
          const opacity = !levelCode ? 1 : layer.code === levelCode ? 1 : 0.1;
          map.setPaintProperty(
            `${layer.code}.${index}-layer`,
            "fill-opacity",
            opacity,
          );
        }
      }
    }
  }

  getMapLayersByLevel(level) {
    const { mapLayers } = this.state;
    const layersLevel = [];
    for (const layerKey in mapLayers) {
      const layer = mapLayers[layerKey];
      if (layer.code.includes(`L${level}.`)) {
        layersLevel.push(layer);
      }
    }
    return layersLevel;
  }

  showMapSpinner() {
    this.props.showSpinner({
      messageI18n: this.props.t("loading_map"),
      classes: "cover",
    });
  }

  unselectRegion(level) {
    const { map } = this.state;
    this.toggleLayerOpacity(level);
    const mapLayersLevel = this.getMapLayersByLevel(level);
    this.orderLayers(map, mapLayersLevel);
    this.setMapCenter();
    this.setState({ selectedRegion: {} });
  }

  goToLevelLayer(region, level, layer) {
    const { map, currentLevel } = this.state;
    const mapLayersLevel = this.getMapLayersByLevel(level);
    if (level !== currentLevel) {
      this.switchLayerLevel(level);
    }
    this.moveMap(layer);
    this.orderLayers(map, mapLayersLevel, region.code);
    this.toggleLayerOpacity(level, region.code);
  }

  loadLayerMissing(region, level) {
    const { ancestryUnlocked } = this.state;
    this.switchLayerLevel();
    this.loadLayer(ancestryUnlocked[level - 1], level, region);
  }

  onRegionClick(region, level, force = false) {
    const { mapLayers, selectedRegion, isMapReady } = this.state;
    if (!isMapReady) {
      return;
    }
    // if the user clicks on the same selected region, force = false = user click
    if (!force && selectedRegion.code === region.code) {
      this.unselectRegion(level);
    } else {
      // going to the clicked region
      const layer = mapLayers[region.code] || {};
      if (layer.extension) {
        this.goToLevelLayer(region, level, layer);
      } else if (!layer.code) {
        this.loadLayerMissing(region, level);
      }
      this.setState({
        currentLevel: level,
        selectedRegion: region,
      });
    }
    this.resetScroll();
  }

  moveMap(layer) {
    const { map } = this.state;
    const bbox = layer.extension?.bbox || [];
    map.fitBounds(
      [
        [bbox[0], bbox[1]],
        [bbox[2], bbox[3]],
      ],
      this.mapMovementOptions,
    );
  }

  resetScroll() {
    const el_header = document.querySelector(".mapboxgl-canvas");
    if (el_header) {
      el_header.scrollIntoView();
    }
  }

  renderAncestryRegionButton(region, level) {
    const { t } = this.props;
    const { selectedRegion, currentLevel } = this.state;
    let classSelected = "";
    let regionStyles = { backgroundColor: region.color };
    if (selectedRegion && region.code === selectedRegion.code) {
      classSelected = "selected";
      regionStyles.backgroundColor = regionStyles.backgroundColor + "dd";
    } else if (region.level === currentLevel) {
      classSelected = "selected-ligth";
      regionStyles.backgroundColor = regionStyles.backgroundColor + "dd";
    }
    const regionHidden = Boolean(!region.percentage);
    const percentage = regionHidden ? <LockIcon /> : region.percentage + "%";
    const classDisabled = regionHidden ? "disabled" : "";
    return (
      <div
        className={`ancestry-table-button ancestry-table-button-level${level} ${classSelected} ${classDisabled}`}
        style={level === 1 ? regionStyles : null}
        onClick={() => !regionHidden && this.onRegionClick(region, level)}
      >
        <div className="color-legend">
          <div style={regionStyles}></div>
        </div>
        <div className="region-name">
          {regionHidden ? `${region.region_hidden_count} ` : ""}
          {t(region.region_name_i18n)}
        </div>
        <div className="percentage">{percentage}</div>
      </div>
    );
  }

  getChildRegions(ancestry, level, regionParent, regionsOrdered) {
    ancestry[level].forEach((region) => {
      if (regionParent.code === region.parent_code) {
        region.level = level + 1;
        regionsOrdered.push(region);
        // get the next level of child regions if it exists
        if (ancestry[level + 1]) {
          this.getChildRegions(ancestry, level + 1, region, regionsOrdered);
        }
      }
    });
  }

  orderRegionsHierarchically(ancestry) {
    const regionsOrdered = [];
    ancestry?.[0]?.forEach((region) => {
      region.level = 1;
      regionsOrdered.push(region);
      if (ancestry[1]) {
        this.getChildRegions(ancestry, 1, region, regionsOrdered);
      }
    });
    return regionsOrdered;
  }

  groupHiddenRegions(regions) {
    const regionsGroupedByParent = [];
    const regionsGrouped = [];
    const childrenGrouped = {};
    const regionsChildren = groupBy(regions, ({ parent_code }) => parent_code);
    /* there are two types of nested regions, 
      1- parents: level 1 has exactly the number of children
      2- level: for levels greater than 1, there are multiple regions and each one has children
    */
    // grouping by parents, level 1 and 2 done, the 3 is flattened
    regions.forEach((region) => {
      if (region.percentage) {
        regionsGroupedByParent.push(region);
      } else if (!region.percentage && !childrenGrouped[region.parent_code]) {
        region.region_hidden_count = regionsChildren[region.parent_code].length;
        regionsGroupedByParent.push(region);
        childrenGrouped[region.parent_code] = true;
      }
    });
    // grouping the level 3
    let level3RegionChildren = 0;
    regionsGroupedByParent.forEach((region, index) => {
      if (!region.percentage && region.code.includes("L3.")) {
        level3RegionChildren += region.region_hidden_count;
        if (regionsGroupedByParent[index + 1]?.code.includes("L3.")) {
          return;
        }
        region.region_hidden_count = level3RegionChildren;
      }
      regionsGrouped.push(region);
      level3RegionChildren = 0;
    });
    return regionsGrouped;
  }

  changeCurrentLevel(level) {
    const { currentLevel } = this.state;
    if (level !== currentLevel) {
      this.switchLayerLevel(level);
    }
    this.setState({ currentLevel: level }, () => {
      this.unselectRegion(level);
    });
  }

  renderTabs() {
    const { numLevels, currentLevel, gatingLevel } = this.state;
    if (CONFIG.FF_DISPLAY_ANCESTRY_TABLE_TABS) {
      return (
        <div className="tabs">
          {[...Array(numLevels)].map((nn, index) => {
            const level = index + 1;
            const classActive = level === currentLevel ? "active" : "";
            const classDisabled = level > gatingLevel ? "disabled" : "";
            return (
              <div
                className={`level ${classActive} ${classDisabled}`}
                key={level}
                onClick={() => {
                  level <= gatingLevel && this.changeCurrentLevel(level);
                }}
              >
                {classDisabled && <LockIcon className="icon" />}
                {level}
              </div>
            );
          })}
        </div>
      );
    }
  }

  renderAncestryList() {
    const { ancestry, currentLevel } = this.state;
    const { t } = this.props;
    const regionsOrderedHierarchically =
      this.orderRegionsHierarchically(ancestry);
    const regionsGrouped = this.groupHiddenRegions(
      regionsOrderedHierarchically,
    );
    return (
      <div className="content-table">
        {this.renderTabs()}
        <div className="level-text">
          <span>
            {t("ancestry_table_current_level", {
              level: currentLevel,
            })}
          </span>
        </div>
        <div className="ancestry-list">
          {regionsGrouped.map((region, index) => {
            return (
              <div key={index} className="ancestry-region">
                {this.renderAncestryRegionButton(region, region.level)}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  changeTablePosition(position) {
    const { mobileTablePosition } = this.state;
    const newPosition =
      this.tablePositions.indexOf(mobileTablePosition) + position;
    this.tablePositions[newPosition] &&
      this.setState({ mobileTablePosition: this.tablePositions[newPosition] });
  }

  renderAncestryPanel() {
    const { ancestryUnlocked, mobileTablePosition } = this.state;
    if (!ancestryUnlocked) {
      return null;
    }
    const { t } = this.props;
    return (
      <div className={`ancestry-panel ${mobileTablePosition}`}>
        <div className="ancestry-table">
          <Swipeable
            onSwipedUp={() => this.changeTablePosition(1)}
            onSwipedDown={() => this.changeTablePosition(-1)}
            preventScrollOnSwipe={true}
          >
            <div className="slider-bar">
              <div className="bar"></div>
            </div>
            <div className="title">{t("ancestry_table_title")}</div>
          </Swipeable>
          {this.renderAncestryList()}
        </div>
      </div>
    );
  }

  // class="px-5 pb-4 pb-sm-0 btn btn-link"
  updateUserAncestryResults(e, user_decisition) {
    const user_agree_later = true;
    let attribute = true;
    if (user_decisition === ANCESTRY.USER_AGREE_MAYBE_LATER) {
      attribute = "later";
    }

    this.props
      .post_user_attributes_post(ATTRIBUTES.ACCEPT_ANCESTRY_RESULTS, attribute)
      .then(() => {
        if (attribute === true) {
          this.getAncestry();
        } else {
          utils.set_local_storage(
            LOCAL_STORAGE.ANCESTRY_AGREEMENT_LATER,
            user_agree_later,
          );
          this.setState({ ancestry_user_agree_later: user_agree_later });
        }
      })
      .catch((error) => {
        this.setState({
          errors: {
            system: `We weren't able to process the request, please try again later.`,
          },
        });
      });
  }

  renderAncestryUserAgree() {
    let ancestry_agree_components = [];
    if (utils.is_json_string(this.props.t("ancestry_agreement_description"))) {
      ancestry_agree_components = JSON.parse(
        this.props.t("ancestry_agreement_description"),
      );
    }
    return (
      <>
        <div className="ancestry-locked-map-cover" />
        <div className="ancestry-locked">
          <div className="ancestry-locked-box-wrapper">
            <div className="ancestry-locked-icon-container">
              <AncestryUserAgreeIcon />
            </div>
            <div className="ancestry-locked-content">
              <div className="ancetry-locked-content-title">
                <HtmlContentComponent
                  markup={this.props.t("ancestry_agreement_title")}
                />
              </div>
              <div className="ancetry-conset-content-subtitle">
                <HtmlContentComponent
                  markup={this.props.t("ancestry_agreement_subtitle")}
                />
              </div>

              <div className="ancetry-locked-content-description">
                {ancestry_agree_components.length > 0 ? (
                  ancestry_agree_components.map((c, key) => (
                    <AncestrySubcompRenderer
                      key={key}
                      type={c.type}
                      component={c}
                      subcomponentKey={key}
                    />
                  ))
                ) : (
                  <HtmlContentComponent
                    markup={this.props.t("ancestry_agreement_description")}
                  />
                )}
              </div>
              <div className="ancestry-locked-btns pt-3 pb-3 flex-sm-row justify-content-sm">
                <button
                  className="py-3 pr-3 pr-sm-3  inlineText btn btn-link"
                  onClick={(e) => {
                    this.updateUserAncestryResults(
                      e,
                      ANCESTRY.USER_AGREE_MAYBE_LATER,
                    );
                  }}
                >
                  {this.props.t("Maybe Later")}
                </button>
                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    this.updateUserAncestryResults(e, ANCESTRY.USER_AGREE);
                  }}
                >
                  {this.props.t("I Agree")}
                </button>
              </div>
            </div>

            <div className="ancestry-locked-suggested-surveys">
              <SuggestedSurveys type="card" />
            </div>
            <Footer />
          </div>
        </div>
      </>
    );
  }

  renderAncestryLaterAgreement() {
    let ancestry_components = [];
    if (
      utils.is_json_string(this.props.t("ancestry_agreement_later_description"))
    ) {
      ancestry_components = JSON.parse(
        this.props.t("ancestry_agreement_later_description"),
      );
    }
    return (
      <>
        <div className="ancestry-locked-map-cover" />
        <div className="ancestry-locked">
          <div className="ancestry-locked-box-wrapper">
            <div className="ancestry-locked-icon-container">
              <AncestryUserMaybeLaterAgreeIcon />
            </div>
            <div className="ancestry-locked-content">
              <div className="ancetry-locked-content-title">
                <HtmlContentComponent
                  markup={this.props.t("ancestry_agreement_later_title")}
                />
              </div>
              <div className="ancetry-conset-content-subtitle">
                <HtmlContentComponent
                  markup={this.props.t("ancestry_agreement_later_subtitle")}
                />
              </div>

              <div className="ancetry-locked-content-description">
                {ancestry_components.length > 0 ? (
                  ancestry_components.map((c, key) => (
                    <AncestrySubcompRenderer
                      key={key}
                      type={c.type}
                      component={c}
                      subcomponentKey={key}
                    />
                  ))
                ) : (
                  <HtmlContentComponent
                    markup={this.props.t(
                      "ancestry_agreement_later_description",
                    )}
                  />
                )}
              </div>

              <div className="ancestry-locked-btns pt-3 pb-3 flex-sm-row justify-content-sm">
                <Button
                  variant="link"
                  className="py-3 pr-3 pr-sm-3 inlineText btn btn-link"
                  onClick={() =>
                    this.setState(
                      { ancestry_user_agree_later: false },
                      utils.set_local_storage(
                        LOCAL_STORAGE.ANCESTRY_AGREEMENT_LATER,
                        false,
                      ),
                    )
                  }
                >
                  {this.props.t("Change Response")}
                </Button>
                <Link className="btn btn-primary" to={PAGES.DASHBOARD}>
                  {this.props.t("Go Home")}
                </Link>
              </div>
            </div>
            <div className="ancestry-locked-suggested-surveys">
              <SuggestedSurveys type="card" />
            </div>
            <Footer />
          </div>
        </div>
      </>
    );
  }

  renderUserAgreePanel() {
    return (
      <>
        {this.state.ancestry_user_agree_later
          ? this.renderAncestryLaterAgreement()
          : this.renderAncestryUserAgree()}
      </>
    );
  }

  renderLockedMessage() {
    const unavailable = this.state.gatingLevel === -1;
    const locked = this.state.gatingLevel === 0;

    let ancestry_components = [];
    if (utils.is_json_string(this.props.t("ancestry_components"))) {
      ancestry_components = JSON.parse(this.props.t("ancestry_components"));
    }

    return (
      <>
        <div className="ancestry-locked-map-cover" />
        <div className="ancestry-locked">
          <div className="ancestry-locked-box-wrapper">
            <div className="ancestry-locked-icon-container">
              <AncestryLockIcon />
            </div>
            <div className="ancestry-locked-content">
              <div className="ancetry-locked-content-title">
                <HtmlContentComponent
                  markup={this.props.t("ancestry_unavailable_title")}
                />
              </div>
              <div className="ancetry-locked-content-subtitle">
                <HtmlContentComponent
                  markup={this.props.t("ancestry_unavailable_subtitle")}
                />
              </div>

              {ancestry_components.length > 0 ? (
                ancestry_components.map((c, key) => (
                  <AncestrySubcompRenderer
                    key={key}
                    type={c.type}
                    component={c}
                    subcomponentKey={key}
                  />
                ))
              ) : (
                <HtmlContentComponent
                  markup={this.props.t("ancestry_components")}
                />
              )}
            </div>

            <div className="ancestry-locked-suggested-surveys">
              <SuggestedSurveys type="card" />
            </div>
            <Footer />
          </div>
        </div>
      </>
    );
  }

  render() {
    const is_not_ancestry_map_displayed = this.state.gatingLevel === -1;
    return (
      <section
        className={`ancestry ${is_not_ancestry_map_displayed ? "overflow-visible-mobile" : ""}`}
      >
        <div className="embed-responsive embed-responsive-16by9">
          <div
            ref={(el) => (this.mapContainer = el)}
            className="embed-responsive-item"
            style={{ height: "60vh" }}
          />
        </div>
        {this.state.gatingLevel > 0
          ? this.renderAncestryPanel()
          : this.state.ancestry_agreement_panel && CONFIG.ANCESTRY_USER_AGREE
            ? this.renderUserAgreePanel()
            : this.renderLockedMessage()}
        {this.ancestry_user_agree &&
        this.state.gatingLevel > 0 &&
        this.state.gatingLevel < 4 ? (
          <div className="ancestry-page-message">
            <AncestryMessage />
          </div>
        ) : null}

        <div
          className="dashboard-loader"
          style={!this.state.loading ? { opacity: 0 } : { opacity: 1 }}
        >
          <div className="dashboard-loader-wrapper">
            <img className="rgc-spinner" src={RGCLogo} alt="" />
            <div className="mt-2 text-center font-callout">
              {this.props.t("loading your ancestry")}
            </div>
          </div>
        </div>

        {CONFIG.REFERRAL && CONFIG.REFERRAL_DASHBOARD_BANNER ? (
          <div className="ancestry-referral-banner">
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-9 col-xl-8 center-section">
                  <ModalReferralBanner />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
  workflow: state.workflow.workflow,
  workflowIsLoading: state.workflow.workflowIsLoading,
});

export default withRouter(
  connect(mapStateToProps, {
    ancestry_get,
    latlng_get,
    getGeometry,
    workflow_get,
    post_user_attributes_post,
    showSpinner,
    hideSpinner,
  })(withTranslation("ancestry")(Ancestry)),
);
