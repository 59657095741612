import { useTranslation } from "react-i18next";
import styles from "./recovery-banner.module.scss";
import { Button, Modal } from "react-bootstrap";
import recoveryBannerOneImage from "../../assets/images/superhero.svg";
import recoveryBannerTwoImage from "../../assets/images/hearthands.svg";
import PAGES from "src/constants/pages";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setBannerCount } from "src/actions/recoveryBannerAction.js";
import { utils_consent_banner } from "src/utils/utils_consent_banner";

export function RecoveryBanner({ canShow, onClose }) {
  const dispatch = useDispatch();
  const bannerCount = useSelector((state) => state.bannerCount);
  const [currentBanner, setCurrentBanner] = useState(null);
  const [show, setShow] = useState(false);
  const isMaxReached = utils_consent_banner.is_max_reached(bannerCount);

  useEffect(() => {
    if (canShow && !isMaxReached) {
      const nextBanner = utils_consent_banner.get_next_banner(currentBanner);
      setCurrentBanner(nextBanner);
      setShow(true);
      dispatch(setBannerCount(bannerCount + 1));
    } else {
      setShow(false);
    }
  }, [canShow]);

  const bannerImage =
    currentBanner === "hero"
      ? recoveryBannerOneImage
      : currentBanner === "heart"
        ? recoveryBannerTwoImage
        : null;

  return (
    <Modal
      className={styles.modalResponsive}
      show={show}
      onHide={onClose}
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <div className="d-flex">
        <div className={styles.headerImage}>
          {bannerImage && (
            <img className="img-fluid" src={bannerImage} alt={currentBanner} />
          )}
        </div>
      </div>
      <div className="d-block d-md-none">
        <ModalContentMobile bannerType={currentBanner} onClose={onClose} />
      </div>
      <div className="d-none d-md-block">
        <ModalContentDesktop bannerType={currentBanner} onClose={onClose} />
      </div>
    </Modal>
  );
}

function ModalContentMobile({ bannerType, onClose }) {
  const { t } = useTranslation();

  switch (bannerType) {
    case "hero":
      return (
        <Modal.Body className="text-center">
          <h2 className={`${styles.title}`}>
            {t("Recovery_Banner_One_Title")}
          </h2>
          <h5 className={`${styles.subtitle}`}>
            {t("Recovery_Banner_One_Subtitle")}
          </h5>
          <p className={`${styles.description}`}>
            {t("Recovery_Banner_One_Description")}
          </p>
          <div className="mb-3">
            <Button
              className={`${styles.btnRecoveryBanner} btn-primary`}
              variant="primary"
              type="submit"
              onClick={onClose}
            >
              {t("Recovery_Banner_One_Button")}
            </Button>
          </div>
          <div className="mb-3">
            <Button
              variant="link"
              className={`${styles.btnDismiss} text-muted`}
              href={PAGES.LOGIN}
            >
              {t("Recovery_Banner_One_Dismiss")}
            </Button>
          </div>
        </Modal.Body>
      );
    case "heart":
      return (
        <div className="p-3">
          <Modal.Body className="text-center">
            <h2 className={`${styles.title} mb-4 mt-4`}>
              {t("Recovery_Banner_Two_Title")}
            </h2>
            <h5 className={`${styles.subtitle} mb-4`}>
              {t("Recovery_Banner_Two_Subtitle")}
            </h5>
            <div>
              <Button
                className={`${styles.btnRecoveryBanner} btn-primary mb-4`}
                variant="primary"
                type="submit"
                onClick={onClose}
              >
                {t("Recovery_Banner_Two_Button")}
              </Button>
            </div>
            <div className="mb-4">
              <Button
                variant="link"
                className={`${styles.btnDismiss} text-muted`}
                href={PAGES.LOGIN}
              >
                {t("Recovery_Banner_Two_Dismiss")}
              </Button>
            </div>
          </Modal.Body>
        </div>
      );
    default:
      return null;
  }
}

function ModalContentDesktop({ bannerType, onClose }) {
  const { t } = useTranslation();

  switch (bannerType) {
    case "hero":
      return (
        <Modal.Body className="text-center">
          <h2 className={`${styles.title} mb-4`}>
            {t("Recovery_Banner_One_Title")}
          </h2>
          <h4 className={`${styles.subtitle} mb-3`}>
            {t("Recovery_Banner_One_Subtitle")}
          </h4>
          <p className="mb-4">{t("Recovery_Banner_One_Description")}</p>
          <div className="mb-4">
            <Button
              className={`${styles.btnRecoveryBanner} btn-primary`}
              variant="primary"
              type="submit"
              onClick={onClose}
            >
              {t("Recovery_Banner_One_Button")}
            </Button>
          </div>
          <div className="mb-5">
            <Button variant="link" className="text-muted" href={PAGES.LOGIN}>
              {t("Recovery_Banner_One_Dismiss")}
            </Button>
          </div>
        </Modal.Body>
      );
    case "heart":
      return (
        <Modal.Body className="text-center">
          <h2 className={`${styles.title} mb-4 mt-4`}>
            {t("Recovery_Banner_Two_Title")}
          </h2>
          <h5 className={`${styles.subtitle} mb-4`}>
            {t("Recovery_Banner_Two_Subtitle")}
          </h5>
          <div className="mb-4">
            <Button
              className={`${styles.btnRecoveryBanner} btn-primary`}
              variant="primary"
              type="submit"
              onClick={onClose}
            >
              {t("Recovery_Banner_Two_Button")}
            </Button>
          </div>
          <div className="mb-4">
            <Button
              variant="link"
              className={`${styles.btnDismiss} text-muted`}
              href={PAGES.LOGIN}
            >
              {t("Recovery_Banner_Two_Dismiss")}
            </Button>
          </div>
        </Modal.Body>
      );
    default:
      return null;
  }
}
