import { useState } from "react";
import { Button } from "react-bootstrap";
import { withTranslation } from "react-i18next";

// Use our own copy of the default logo
import defaultLogoUrl from "../../assets/images/health-system-default.png";

function fixLogoUrl(sourceUrl) {
  // Attempt to fix invalid URL
  //
  // Sometimes 1uphealth returns broken urls for the providers logos:
  //
  // "name": "Columbus Skin Surgery Center",
  // "logo": "undefined/health-system-default.png",
  //
  // "name": "Commonwealth Pain Specialists",
  // "logo": "undefined/null",
  //
  // "name": "Community Health Mark",
  // "logo": "undefined/https://1uphealth-assets.s3-us-west-2.amazonaws.com/systems/health-system-default.png",
  //
  // "name": "Cerner Health Systems (demo)",
  // "logo": "undefined/cerner.png",
  //
  // "name": "CommonSpirit Health - Mountain Region",
  // "logo": "undefined/https://media.licdn.com/dms/image/D560BAQF5nVUzhuA-0g/company-logo_200_200/0/1719855657083/commonspirithealth_logo?e=1729123200&v=beta&t=oVMGp8YDxzMF4-KTU422nONFFa8cIxwrj2VLLkUj9nE",
  //
  // "name": "Blue Cross Blue Shield Nebraska",
  // "logo": null,

  const baseUrl = "https://1uphealth-assets.s3-us-west-2.amazonaws.com/systems";
  const noLogoUrls = [
    "health-system-default.png",
    "null",
    "https://1uphealth-assets.s3-us-west-2.amazonaws.com/systems/health-system-default.png",
  ];

  if (!sourceUrl) {
    return defaultLogoUrl;
  }

  if (sourceUrl?.startsWith("undefined")) {
    const url = sourceUrl.replace("undefined/", "");
    if (noLogoUrls.includes(url)) {
      return defaultLogoUrl;
    }
    if (!url.startsWith("http")) {
      return `${baseUrl}/${url}`;
    }
    return url;
  }

  return sourceUrl;
}

const EMRListItem = (props) => {
  const { item, connectCopy, connectOnClick, t } = props;
  const [logoError, setLogoError] = useState(false);

  // Attempt to fix logo url if it comes broken
  const logoUrl = fixLogoUrl(item.logo);

  return (
    <div key={item.id} className="emr-list-item mb-2">
      <div className="emr-list-item-image">
        <img
          src={logoError ? defaultLogoUrl : logoUrl}
          alt=""
          // fallback on logo loading error
          // https://www.codu.co/articles/setting-a-fallback-image-in-html-for-broken-or-missing-images-otom_bhg
          onError={
            !logoError
              ? () => {
                  setLogoError(true);
                }
              : null
          }
        />
      </div>
      <div className="emr-list-item-text">
        <p>{item.name || t("Health Institution")}</p>
        <small>{`${[item.city, item.state, item.zipcode].filter((x) => Boolean(x)).join(", ")}`}</small>
      </div>
      <div className="emr-list-item-button">
        {!props.hideConnect ? (
          <Button
            className="btn-sm emr-connect-button"
            onClick={() => connectOnClick(item)}
          >
            {connectCopy}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default withTranslation()(EMRListItem);
