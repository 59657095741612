import { REDUX_ACTION } from "src/constants/reduxAction";
import { api_user } from "../api/api_user";
import { utils } from "../utils/utils_general";
import { utils_api } from "../utils/utils_api";

const SESSION_STORAGE_UTM_KEY = "rgc_dtp.utm";

export function set_utm_params(params) {
  return (dispatch) => {
    sessionStorage.setItem(SESSION_STORAGE_UTM_KEY, JSON.stringify(params));
    dispatch({
      type: REDUX_ACTION.SET_UTM_PARAMS,
      payload: params,
    });
  };
}

export function get_utm_params() {
  return (dispatch) => {
    const storedParams = sessionStorage.getItem(SESSION_STORAGE_UTM_KEY);
    if (storedParams) {
      dispatch({
        type: REDUX_ACTION.SET_UTM_PARAMS,
        payload: JSON.parse(storedParams),
      });
    }
  };
}

export function update_user_utm_params(params) {
  const data = {
    utm: params,
  };
  return (dispatch) => {
    utils.loadingSpinner(
      dispatch,
      REDUX_ACTION.SPINNER,
      "update_user_utm_params",
    );
    const success = (response) =>
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_user.update_user(success, fail, data);
  };
}
