import { useEffect } from "react";
import ConsentCompForm from "./consent_components/consent_comp_form";
import ConsentCompFormQn from "./consent_components/consent_comp_form_qn";
import ConsentCompEsign from "./consent_components/consent_comp_esign";
import { CONSENT_COMPONENT } from "src/constants/consentComponents";
import ConsentCompEsignConfirm from "./consent_components/consent_comp_esign_confirm";
import BlankComponent from "src/components/core/Blank/BlankComponent";

const renderComponent = ({
  component,
  index,
  onComplete,
  onCancel,
  signature,
  signatureType,
  signatureCount,
  user_response,
}) => {
  switch (component.type) {
    case CONSENT_COMPONENT.FORM:
      return (
        <ConsentCompForm
          component={component}
          index={index}
          onComponentComplete={(value) => onComplete(value)}
          user_response={user_response}
        />
      );
    case CONSENT_COMPONENT.FORM_QN_SOLO:
      return (
        <ConsentCompFormQn
          component={component}
          index={index}
          onComponentComplete={(value) => onComplete(value)}
          user_response={user_response}
        />
      );
    case CONSENT_COMPONENT.FORM_QN_KNOWLEDGECHECK:
      return (
        <ConsentCompFormQn
          component={component}
          index={index}
          onComponentComplete={(value) => onComplete(value)}
          user_response={user_response}
        />
      );
    case CONSENT_COMPONENT.ESIGN:
      return (
        <ConsentCompEsign
          component={component}
          index={index}
          onComponentComplete={(sigDetails) => onComplete(sigDetails)}
          user_response={user_response}
          signatureObject={signature}
          signatureType={signatureType}
          signatureCount={signatureCount}
        />
      );
    case CONSENT_COMPONENT.CONFIRM:
      return (
        <ConsentCompEsignConfirm
          component={component}
          index={index}
          onComponentComplete={(value) => onComplete(value)}
          onCancel={() => onCancel()}
          signature={signature}
        />
      );
    default:
      return <BlankComponent />;
  }
};

export const ConsentCompRenderer = ({
  component,
  index,
  onComplete,
  onCancel,
  signature,
  signatureType,
  signatureCount,
  user_response,
}) => {
  useEffect(() => {
    // scroll to top when clicking 'continue' within a consent
    window.scrollTo(0, 0);
  }, [index]);

  return renderComponent({
    component,
    index,
    onComplete,
    onCancel,
    signature,
    signatureType,
    signatureCount,
    user_response,
  });
};

export default ConsentCompRenderer;
