import classNames from "classnames";
import styles from "./referral-landing-page-b-content.module.scss";
import { Link } from "react-router-dom";
import PAGES from "../../../constants/pages";
import { useTranslation } from "react-i18next";
import globeSmall from "../../../assets/images/ancestry-globe-small-white-bg.gif";
import { ReactComponent as IconHome } from "../../../assets/icons/History.svg";
import { ReactComponent as IconProfile } from "../../../assets/icons/Test.svg";
import { ReactComponent as IconSurveys } from "../../../assets/icons/Saliva.svg";
import { ReactComponent as IconEmail } from "../../../assets/icons/Ancestry.svg";
import { ReactComponent as IconClappingHand } from "../../../assets/icons/icon_high_five.svg";

export default function ReferralLandingPageBMobileContent(props) {
  const { t } = useTranslation();
  const { loginUser } = props;

  function LandingSmallCards() {
    return (
      <div className={classNames(styles.referralXSCardsWrapper)}>
        <div className={classNames(styles.referralXSCard)}>
          <IconProfile />
          <div className={classNames(styles.referralXSCardDescription)}>
            <h4>{t("Referral_B_Card_Step_1")} </h4>
            <h3>{t("Referral_B_Card_Title_1")}</h3>
          </div>
        </div>
        <div className={classNames(styles.referralXSCard)}>
          <IconHome />
          <div className={classNames(styles.referralXSCardDescription)}>
            <h4>{t("Referral_B_Card_Step_2")} </h4>
            <h3>{t("Referral_B_Card_Title_2")}</h3>
          </div>
        </div>
        <div className={classNames(styles.referralXSCard)}>
          <IconSurveys />
          <div className={classNames(styles.referralXSCardDescription)}>
            <h4>{t("Referral_B_Card_Step_3")} </h4>
            <h3>{t("Referral_B_Card_Title_3")}</h3>
          </div>
        </div>
        <div className={classNames(styles.referralXSCard)}>
          <IconEmail />
          <div className={classNames(styles.referralXSCardDescription)}>
            <h4>{t("Referral_B_Card_Step_4")}</h4>
            <h3>{t("Referral_B_Card_Title_4")}</h3>
          </div>
        </div>
        <Link
          to={t("Referral_B_Hands_Url")}
          className={classNames(styles.referralXSHands)}
        >
          <h1>{t("Referral_B_Hands_Left")}</h1>
          <h2>{t("Referral_B_Hands_Right")}</h2>
          <IconClappingHand />
        </Link>
        <div className={classNames(styles.referralXSCard)}>
          <p className={classNames(styles.referralXSBoldParagraph)}>
            {t("Referral_B_Ancestry_Title")}
          </p>
          <p className={classNames(styles.referralXSParagraph)}>
            {t("Referral_B_Ancestry_Subtitle")}
          </p>
          <div className={classNames(styles.referralXSButton)}>
            <Link to={""}>{t("Referral_B_Ancestry_Button")}</Link>
          </div>
          <img
            src={globeSmall}
            alt="globe-small"
            className={classNames(styles.referralXSImage)}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={classNames(styles.referralXSWrapper)}>
        <div className={classNames(styles.referralXSTitle)}>
          <h1>{t("Referral_B_Title")}</h1>
        </div>
        <div className={classNames(styles.referralXSDescription)}>
          <p>
            {t("Referral_B_SubTitle", {
              first_name: loginUser?.first_name || "",
              last_name: loginUser?.last_name || "",
            })}
          </p>
          <p>{t("Referral_B_Description")}</p>
        </div>
        <div className={classNames(styles.referralXSButton)}>
          <Link to={PAGES.SIGNUP}>{t("Referral_B_Button")}</Link>
        </div>
        <LandingSmallCards />
      </div>
    </>
  );
}
