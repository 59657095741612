import { useTranslation } from "react-i18next";
import landingTick from "../../../assets/icons/check-circle.png";
import magnifying from "../../../assets/images/ancestry_magnifying_glass.svg";
import { Link } from "react-router-dom";
import PAGES from "src/constants/pages";

const AncestryLandingPageContent = () => {
  return (
    <div>
      <DiversityBanner />
      <ArticlesBanner />
    </div>
  );
};

const DiversityBanner = () => {
  const { t } = useTranslation();
  return (
    <div className="page-content-diversity">
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <div className="diversity-topic-content">
              <div className="diversity-topic">
                <h2 className="mb-4">{t("Ancestry_Diversity_Title")}</h2>
                <ul className="list-unstyled">
                  <li className="d-flex align-items-center mb-3">
                    <img
                      alt="landing-tick"
                      className="landing-tick mr-2"
                      src={landingTick}
                    />
                    <span>{t("Ancestry_Diversity_bullet_1")}</span>
                  </li>
                  <li className="d-flex align-items-center mb-3">
                    <img
                      alt="landing-tick"
                      className="landing-tick mr-2"
                      src={landingTick}
                    />
                    <span>{t("Ancestry_Diversity_bullet_2")}</span>
                  </li>
                  <li className="d-flex align-items-center mb-3">
                    <img
                      alt="landing-tick"
                      className="landing-tick mr-2"
                      src={landingTick}
                    />
                    <span>{t("Ancestry_Diversity_bullet_3")}</span>
                  </li>
                </ul>
                <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center landing-hero-links">
                  <Link
                    className="landing-header-button mb-2 mb-md-0 mr-md-2"
                    to={PAGES.SIGNUP}
                  >
                    {t("Ancestry_Diversity_button_1")}
                  </Link>
                  <Link className="landing-header-link" to={PAGES.HOME_PAGE}>
                    {t("Ancestry_Diversity_button_2")}
                    <span className="d-none d-sm-inline">
                      <i className="icon-arrow-right" />
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5"></div>
        </div>
      </div>
    </div>
  );
};

const ArticlesBanner = () => {
  const { t } = useTranslation();
  const articleImageUrl =
    process.env.REACT_APP_ANCESTRY_CONTENT_ARTICLE_IMG_URL;
  const articleUrl = process.env.REACT_APP_ANCESTRY_CONTENT_ARTICLE_URL;
  return (
    <div className="page-content-articles bg-light">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-4 mb-4 mb-lg-0">
            <div className="h-100 d-flex flex-column justify-content-center align-items-center align-items-lg-start text-center text-lg-left">
              <img src={magnifying} alt="" className="mb-4" />
              <h2>{t("Ancestry_Article_Topic_Title")}</h2>
            </div>
          </div>
          <div className="col-12 col-md-12 col-lg-8">
            <div className="card h-100 border-0 shadow-sm article-card">
              <div className="card-body d-flex flex-column">
                <div className="row h-100">
                  <div className="col-md-4 mb-3 mb-md-0">
                    {articleImageUrl ? (
                      <img
                        src={articleImageUrl}
                        alt="Article preview"
                        className="img-fluid h-100 object-fit-cover article-image"
                      />
                    ) : null}
                  </div>
                  <div className="col-md-8 d-flex flex-column">
                    <h3 className="card-title h4 mb-3">
                      {t("Ancestry_Article_Header")}
                    </h3>
                    <p className="card-text flex-grow-1 paragraph-truncate">
                      {t("Ancestry_Article_Paragraph")}
                    </p>
                    <div className="mt-auto">
                      <a
                        href={articleUrl}
                        className="read-more text-decoration-none"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t("Ancestry_Keep_Reading_Button")}
                        <div className="d-none d-sm-inline">
                          <i className="exit-icon ms-2" />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AncestryLandingPageContent;
