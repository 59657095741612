import "reflect-metadata";
import { jwtDecode } from "jwt-decode";
import { inject, injectable } from "inversify";
import { LOCAL_STORAGE } from "src/constants/localStorage";
import { utils } from "src/utils/utils_general";
import { UserData } from "./types/UserTypes";
import * as IStore from "./types/IStore";

export interface IUserService {
  getCurrentUser(): UserData | null;
}

type UserStatus = {
  [key: string]: string;
};

export const USER_STATUS: UserStatus = {
  ENROLLMENT: "enrollment",
  ACTIVE: "active",
  PENDING: "pending",
};

@injectable()
class UserService implements IUserService {
  @inject("store") private store!: IStore.IStore;

  private loadUserFromToken(token: string | null): UserData | null {
    return token ? (jwtDecode(token) as UserData) : null;
  }

  getCurrentUser(): UserData | null {
    const token = utils.get_local_storage(LOCAL_STORAGE.USER) || null;
    return this.loadUserFromToken(token);
  }

  checkUserStatus(status: string, user: UserData | null): boolean {
    if (user?.status === status) {
      return true;
    }
    return false;
  }

  isUserStatusActive(user: UserData | null): boolean {
    return this.checkUserStatus(USER_STATUS.ACTIVE, user);
  }

  isUserStatusPending(user: UserData | null): boolean {
    return this.checkUserStatus(USER_STATUS.PENDING, user);
  }

  isUserStatusEnrollment(user: UserData | null): boolean {
    return this.checkUserStatus(USER_STATUS.ENROLLMENT, user);
  }

  isUserEmailVerificationSkipped(user: UserData | null): boolean {
    if (user && user.email_verification_skipped) {
      return true;
    }
    return false;
  }
}

export { UserService };
