import { ChangeEvent, useState, useRef } from "react";
import { Form } from "react-bootstrap";
import { DropdownItem } from "src/components/core/Dropdown/DropdownItem";
import { InnerWorkflowStepProps } from "src/services/types/WorkflowTypes";
import PhoneNumberComponent from "src/components/core/PhoneNumber/PhoneNumberComponent";
import { useTranslation } from "react-i18next";
import { statesAbbreviated } from "src/data/usaStates";
import { useCurrentUser } from "src/services/UserHooks";
import { useValidate } from "src/services/validation/ValidationHooks";
import { OrderDnaKitInterface } from "src/services/types/validations/OrderDnaKit";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { selectDnaKitOrderPayload } from "src/store/DnaKit/DnaKitSelectors";
import { doSetDnaKitOrderPayload } from "src/store/DnaKit/DnaKitActions";
import ZipCodeComponent from "src/components/core/ZipCode/ZipCodeComponent";
import AutoCompleteComponent, {
  CandidateEntry,
} from "src/components/core/AutoComplete/AutoCompleteComponent";
import getGoogleAddresses, {
  Prediction,
  extractComponentsFrom,
  getGoogleAddressDetails,
} from "src/api/addressValidation";

import classNames from "classnames/bind";

import InnerWorkflowActions from "src/components/WorkflowNext/InnerWorkflow/InnerWorkflowActions";
import AddressNotRecognizedConfirm from "./AddressNotRecognizedConfirm";
import OrderDnaKitLayout from "./OrderDnaKitLayout";
import styles from "./DnaKitForm.module.scss";

const cx = classNames.bind(styles);

type OrderDnaKitFormStateProps = {
  dnaKitOrderPayload: OrderDnaKitInterface;
};

type OrderDnaKitDispatchProps = {
  setDnaKitOrderPayload: (payload: OrderDnaKitInterface) => void;
};

export type OrderDnaKitFormProps = InnerWorkflowStepProps &
  OrderDnaKitFormStateProps &
  OrderDnaKitDispatchProps;

const { Feedback } = Form.Control;

function OrderDnaKitForm({
  dnaKitOrderPayload,
  setDnaKitOrderPayload,
  onStepComplete,
}: OrderDnaKitFormProps) {
  const [maskedPhone, setMaskedPhone] = useState(dnaKitOrderPayload.phone);
  const [addressLine1, setAddressLine1] = useState(
    dnaKitOrderPayload.addressLine1,
  );
  const [selectedPlaceId, setSelectedPlaceId] = useState<string>();
  const [addressLine1Validated, setAddressLine1Validated] = useState(
    dnaKitOrderPayload.addressLine1Validated,
  );
  const [addressLine2, setAddressLine2] = useState(
    dnaKitOrderPayload.addressLine2,
  );
  const [city, setCity] = useState(dnaKitOrderPayload.city);
  const [state, setState] = useState(dnaKitOrderPayload.state);
  const [zip, setZip] = useState(dnaKitOrderPayload.zip);
  const [startForm, setStartForm] = useState(false);

  const [shouldConfirmAddress, setShouldConfirmAddress] =
    useState<boolean>(false);

  const addressLine1Ref = useRef(null);

  const { t } = useTranslation();
  const { first_name: firstName, last_name: lastName } = useCurrentUser() || {};
  const states: DropdownItem[] = statesAbbreviated;
  const { validate, getError, clearError } = useValidate<OrderDnaKitInterface>(
    "ORDER_DNA_KIT_VALIDATION",
  );

  const handlePhoneChanged = (e: ChangeEvent<HTMLInputElement>) => {
    clearError("phone");
    setMaskedPhone(e.target.value);
  };
  const handleAddressLine1Changed = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedPlaceId(undefined);
    clearError("addressLine1");
    setAddressLine1(e.target.value);
    setAddressLine1Validated(false);
  };
  const handleAddressLine1Selected = async ({ key, value }: CandidateEntry) => {
    const prediction = value as Prediction;
    const { structured_formatting: structuredFormatting } = prediction;
    const { main_text: mainText } = structuredFormatting;

    setAddressLine1(mainText);
    setSelectedPlaceId(key);

    setCity("");
    setState("");
    setZip("");

    const addressDetailsResult = await getGoogleAddressDetails(key);

    const {
      locality: cityPrediction,
      administrative_area_level_1: statePrediction,
      postal_code: zipPrediction,
      country: countryPrediction,
    } = extractComponentsFrom(
      addressDetailsResult,
      "locality",
      "administrative_area_level_1",
      "postal_code",
      "country",
    );

    const isUSAddress = countryPrediction?.short_name === "US";
    setAddressLine1Validated(
      isUSAddress && zipPrediction?.long_name !== undefined,
    );

    if (isUSAddress) {
      setCity(cityPrediction?.long_name || "");
      setState(statePrediction?.short_name || "");
      setZip(zipPrediction?.long_name || "");
    }
  };
  const handleAddressLine2Changed = (e: ChangeEvent<HTMLInputElement>) => {
    clearError("addressLine2");
    setAddressLine2(e.target.value);
  };
  const handleCityChanged = (e: ChangeEvent<HTMLInputElement>) => {
    clearError("city");
    setCity(e.target.value);
  };
  const handleStateChanged = (e: ChangeEvent<HTMLSelectElement>) => {
    clearError("state");
    setState(e.target.value);
  };
  const handleZipChanged = (e: ChangeEvent<HTMLInputElement>) => {
    clearError("zip");
    setZip(e.target.value);
  };

  const handleOnQuery = async (query: string): Promise<CandidateEntry[]> => {
    const { data } = await getGoogleAddresses(query);
    const { predictions } = data;

    return predictions.map((item) => ({
      key: item.place_id,
      text: item.description,
      value: item,
    }));
  };

  const handleSubmit = () => {
    const payload: OrderDnaKitInterface = {
      phone: maskedPhone.replaceAll(/\(|\)|-| /g, ""),

      addressLine1,
      addressLine1Validated,

      addressLine2,

      city,
      state,
      zip,
    };
    const isValid = validate(payload);

    if (!isValid) {
      return;
    }

    setDnaKitOrderPayload(payload);

    if (addressLine1Validated) {
      onStepComplete();
    } else {
      setShouldConfirmAddress(true);
    }
  };

  const handleConfirmAddressCancel = () => setShouldConfirmAddress(false);

  const handleConfirmAddressConfirm = () => {
    setShouldConfirmAddress(false);
    onStepComplete();
  };

  const handleFocus = (element: any) => {
    if (!startForm) {
      setStartForm(true);
    }
  };

  const canContinue =
    maskedPhone.length > 0 &&
    !maskedPhone.includes("_") &&
    addressLine1.length > 0 &&
    city.length > 0 &&
    state.length > 0 &&
    zip.length > 0 &&
    !zip.includes("_");

  return (
    <>
      <OrderDnaKitLayout title={t("Order Information")}>
        <Form.Group>
          <Form.Label>{t("Name")}</Form.Label>
          <p className={cx(styles.formDisplay)}>
            {firstName} {lastName}
          </p>
        </Form.Group>

        <Form.Group controlId="phone">
          <Form.Label>{t("Phone Number")}</Form.Label>
          <PhoneNumberComponent
            name="phone"
            value={maskedPhone}
            onChange={handlePhoneChanged}
            onFocus={handleFocus}
            isInvalid={!!getError("phone")}
          />
          <Feedback type="invalid">{t(getError("phone") || "")}</Feedback>
        </Form.Group>

        <Form.Group controlId="addressLine1">
          <Form.Label>{t("Address Line 1")}</Form.Label>
          <Form.Control
            name="addressLine1"
            ref={addressLine1Ref}
            type="text"
            placeholder={t("123 Street") as string}
            value={addressLine1}
            autoComplete="off"
            onChange={handleAddressLine1Changed}
            isInvalid={!!getError("addressLine1")}
            onFocus={handleFocus}
          />
          <AutoCompleteComponent
            target={addressLine1Ref}
            query={addressLine1}
            selectedResultKey={selectedPlaceId}
            onQuery={handleOnQuery}
            onResultSelected={handleAddressLine1Selected}
          />
          <Feedback type="invalid">
            {t(getError("addressLine1") || "")}
          </Feedback>
        </Form.Group>

        <Form.Group controlId="addressLine2">
          <Form.Label>{t("Address Line 2")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Apt/Suite #") as string}
            value={addressLine2}
            name="addressLine2"
            autoComplete="off"
            onChange={handleAddressLine2Changed}
            isInvalid={!!getError("addressLine2")}
            onFocus={handleFocus}
          />
          <Feedback type="invalid">
            {t(getError("addressLine2") || "")}
          </Feedback>
        </Form.Group>

        <div className={cx("d-md-flex", styles["flex-rgc-wrap"])}>
          <Form.Group
            controlId="city"
            className={cx("mr-md-2", styles["flex-rgc-cover-line"])}
          >
            <Form.Label>{t("City")}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t("City") as string}
              value={city}
              onChange={handleCityChanged}
              isInvalid={!!getError("city")}
              onFocus={handleFocus}
            />
            <Feedback type="invalid">{t(getError("city") || "")}</Feedback>
          </Form.Group>

          <Form.Group
            controlId="state"
            className={cx(styles["w-md-7em"], "mr-md-2")}
          >
            <Form.Label>{t("State")}</Form.Label>
            <Form.Control
              as="select"
              name="state"
              value={state}
              onChange={handleStateChanged}
              // TODO: fix types
              // @ts-ignore
              className={classNames({ "text-muted": !state })}
              onFocus={handleFocus}
            >
              <option value="">State</option>
              {states.map(({ key, name }) => (
                <option key={key} value={key}>
                  {name}
                </option>
              ))}
            </Form.Control>
            <Feedback type="invalid">{t(getError("state") || "")}</Feedback>
          </Form.Group>

          <Form.Group
            controlId="zipCode"
            className={cx(styles["w-md-7em"], styles["flex-rgc-fill"])}
          >
            <Form.Label>{t("Zip Code")}</Form.Label>
            <ZipCodeComponent
              name="zipCode"
              value={zip}
              onChange={handleZipChanged}
              isInvalid={!!getError("zip")}
              onFocus={handleFocus}
            />
            <Feedback type="invalid">{t(getError("zip") || "")}</Feedback>
          </Form.Group>
        </div>
      </OrderDnaKitLayout>

      <InnerWorkflowActions
        canContinue={canContinue}
        onStepComplete={handleSubmit}
      />

      <AddressNotRecognizedConfirm
        addressLine1={addressLine1}
        addressLine2={addressLine2}
        city={city}
        state={state}
        zip={zip}
        show={shouldConfirmAddress}
        onCancel={handleConfirmAddressCancel}
        onConfirm={handleConfirmAddressConfirm}
      />
    </>
  );
}

const mapDispatchToProps = (dispatch: any): OrderDnaKitDispatchProps => ({
  setDnaKitOrderPayload: (payload: OrderDnaKitInterface) =>
    dispatch(doSetDnaKitOrderPayload(payload)),
});

const mapStateToProps = createStructuredSelector<
  any,
  OrderDnaKitFormStateProps
>({
  dnaKitOrderPayload: selectDnaKitOrderPayload,
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderDnaKitForm);
