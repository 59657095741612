import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PAGES from "src/constants/pages";
import { ReactComponent as IconHome } from "../../assets/icons/nav-home.svg";
import { ReactComponent as IconSurveys } from "../../assets/icons/nav-surveys.svg";
import { ReactComponent as IconAncestry } from "../../assets/icons/nav-ancestry.svg";
import { ReactComponent as IconProfile } from "../../assets/icons/nav-profile.svg";

import { get_user_attributes_get } from "../../actions/userAction";
import { get_survey_list_get } from "../../actions/surveyAction";
import { actioncards_get } from "../../actions/actioncardsAction";
import { FEATURE } from "../../constants/features";

class Nav extends React.Component {
  prefetchData(page) {
    const hideSpinner = true;
    switch (page) {
      case PAGES.DASHBOARD:
        if (!this.props.surveys) {
          this.props.get_survey_list_get(hideSpinner);
        }
        if (!this.props.actionCards) {
          this.props.actioncards_get(hideSpinner);
        }
        break;
      case PAGES.SURVEYS:
        if (!this.props.surveys) {
          this.props.get_survey_list_get(hideSpinner);
        }
        break;
      case PAGES.ANCESTRY:
        break;
      case PAGES.PROFILE:
        if (!this.props.userAttribute) {
          this.props.get_user_attributes_get(hideSpinner);
        }
        break;
      default:
        break;
    }
  }

  onLinksHover(page) {
    this.prefetchData(page);
  }

  render() {
    const currentPath = this.props.location.pathname;
    const { t } = this.props;
    return (
      <nav>
        <div className="container text-center">
          <ul className="clearfix">
            <li>
              <Link
                to={PAGES.DASHBOARD}
                className={currentPath === PAGES.DASHBOARD ? "active" : ""}
                onMouseEnter={() => this.onLinksHover(PAGES.DASHBOARD)}
              >
                <div className="nav-img">
                  <IconHome />
                </div>
                {t("Home")}
              </Link>
            </li>
            {this.props.featureFlag &&
            this.props.featureFlag[FEATURE.SURVEY] ? (
              <li>
                <Link
                  to={PAGES.SURVEYS}
                  className={
                    currentPath.includes(PAGES.SURVEYS) > 0 ? "active" : ""
                  }
                  onMouseEnter={() => this.onLinksHover(PAGES.SURVEYS)}
                >
                  <div className="nav-img">
                    <IconSurveys />
                  </div>
                  {t("Surveys")}
                </Link>
              </li>
            ) : null}
            {this.props.featureFlag &&
            this.props.featureFlag[FEATURE.ANCESTRY] ? (
              <li>
                <Link
                  to={PAGES.ANCESTRY}
                  className={
                    currentPath.includes(PAGES.ANCESTRY) > 0 ? "active" : ""
                  }
                >
                  <div className="nav-img">
                    <IconAncestry />
                  </div>
                  {t("Ancestry")}
                </Link>
              </li>
            ) : null}
            <li>
              <Link
                to={PAGES.PROFILE}
                className={
                  currentPath.includes(PAGES.PROFILE) > 0 ? "active" : ""
                }
                onMouseEnter={() => this.onLinksHover(PAGES.PROFILE)}
              >
                <div className="nav-img">
                  <IconProfile />
                </div>
                {t("Profile")}
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(
  connect(mapStateToProps, {
    get_user_attributes_get,
    get_survey_list_get,
    actioncards_get,
  })(withTranslation()(Nav)),
);
