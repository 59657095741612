import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import BSSpinner from "react-bootstrap/Spinner";
import useSafariTabsFix from "src/utils/useSafariTabsFix";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./index.scss";
import "./i18n";
import configureStore from "./store";
import injectServices from "./services/index";
import {
  ServiceProvider,
  setContainerForLegacyCode,
} from "./services/ServiceProvider";
function AppContainer({ store = null, container = null }) {
  useSafariTabsFix();

  return (
    <Provider store={store}>
      <ServiceProvider container={container}>
        <App />
      </ServiceProvider>
    </Provider>
  );
}

AppContainer.propTypes = {
  store: PropTypes.object,
  container: PropTypes.object,
};

function Main() {
  const [isInitiated, setInitiated] = useState(false);
  const [store, setStore] = useState({});
  const [container, setContainer] = useState({});
  useEffect(() => {
    const newStore = configureStore();
    setStore(newStore);
    const newContainer = injectServices(newStore);
    setContainer(newContainer);
    setInitiated(true);
  }, []);
  if (isInitiated) {
    setContainerForLegacyCode(container);
    return <AppContainer store={store} container={container} />;
  }

  return <BSSpinner animation="border" role="status" variant="primary" />;
}

const container = document.getElementById("root");
const root = createRoot(container);
window.location === window.parent.location && root.render(<Main />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
