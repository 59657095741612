import React from "react";
import { withRouter, Route, Switch, Redirect } from "react-router-dom";
import { inject } from "inversify";
import { connect } from "react-redux";

import { useCurrentUser } from "src/services/UserHooks";
import { FEATURE } from "src/constants/features";
import PAGES from "src/constants/pages";
import { utils } from "src/utils/utils_general";
import { LOCAL_STORAGE } from "src/constants/localStorage";

/* eslint-disable camelcase */
import {
  registration_flow_get,
  reset_registration_flow,
  set_registration_type,
  update_registration_flow,
} from "src/actions/registrationFlowAction";
import { login_post } from "src/actions/loginAction";
import { self_reg_content_get } from "src/actions/selfRegistrationContentAction";
import {
  check_signup_post,
  create_self_register_post,
  get_user_attributes_get,
} from "src/actions/userAction";

/* eslint-enable camelcase */

import { withService } from "src/services/ServiceProvider";
import { TOKEN_TYPE } from "src/constants/tokenType";
import Signup from "./signup-flow/signup";
import SignupConfirmEmail from "./signup-flow/signup_confirm_email";
import SignupAppPolicies from "./signup-flow/signup_app_policies";
import SignupCategorizationValidation from "./signup-flow/signup_categorization_validation";
import SignupTextMedia from "./signup-flow/signup_text_media";
import SignupPassword from "./signup-flow/signup_password";
import SignupVerification from "./signup-flow/signup_verification";
import SignupWorkflowWrapper from "./signup-flow/SignupFlowConsent/SignupWorkflowWrapper";
import consentOld from "../consent/consent-old";

class SignupPage extends React.Component {
  constructor(props) {
    super(props);
    this.userService = this.props.userService;
  }

  componentDidMount() {
    const { user } = this.props;
    this.refreshRegFlow(user);
  }

  componentDidUpdate(prevProps) {
    const prevRegistrationType = prevProps.registrationType;
    const currentRegistrationType = this.props.registrationType;
    const user = this.userService.getCurrentUser();

    if (prevRegistrationType !== currentRegistrationType) {
      this.refreshRegFlow(user);
    }
  }

  redirectBackToSignup = () => this.props.history.push(PAGES.SIGNUP);

  refreshRegFlow(propUser) {
    this.props.registration_flow_get();
  }

  isFeatureEnabled = (featureName) => {
    const { featureFlag } = this.props;
    const checkFeatureFlag = (feature) =>
      !featureFlag || (featureFlag && featureFlag[feature] !== false);
    return checkFeatureFlag(featureName);
  };

  render() {
    const { registrationFlow, match, location } = this.props;
    const { path } = match;
    const hasTokenParam = Boolean(
      utils.get_url_param(location.search, "token"),
    );

    const regFlow =
      registrationFlow ||
      utils.get_local_storage(LOCAL_STORAGE.REGISTRATION_FLOW);

    if (!regFlow) {
      return null;
    }

    return (
      <Switch>
        <Route path={path} exact component={Signup} />
        {hasTokenParam ? (
          <Route exact path={PAGES.SIGNUP_CONFIRM_EMAIL}>
            <SignupConfirmEmail />
          </Route>
        ) : (
          <PrivateRoute exact path={PAGES.SIGNUP_CONFIRM_EMAIL}>
            <SignupConfirmEmail />
          </PrivateRoute>
        )}
        <PrivateRoute exact path={PAGES.SIGNUP_PASSWORD}>
          <SignupPassword />
        </PrivateRoute>
        {this.isFeatureEnabled(FEATURE.VERIFICATION) ? (
          <PrivateRoute exact path={PAGES.SIGNUP_VERIFICATION}>
            <SignupVerification />
          </PrivateRoute>
        ) : null}
        <PrivateRoute exact path={PAGES.SIGNUP_QUESTIONNAIRE}>
          <SignupCategorizationValidation />
        </PrivateRoute>
        <PrivateRoute exact path={PAGES.SIGNUP_SUBWORKFLOW}>
          {regFlow ? (
            <SignupWorkflowWrapper
              regFlow={regFlow}
              stepComponentName="subworkflow"
              redirectBack={this.redirectBackToSignup}
              {...this.props}
            />
          ) : (
            <Redirect to={PAGES.SIGNUP} />
          )}
        </PrivateRoute>
        {this.isFeatureEnabled(FEATURE.ONBOARD_APP_POLICIES) ? (
          <PrivateRoute exact path={PAGES.SIGNUP_APP_POLICIES}>
            <SignupAppPolicies />
          </PrivateRoute>
        ) : null}
        <Route exact path={PAGES.SIGNUP_TEXT}>
          <SignupTextMedia />
        </Route>
      </Switch>
    );
  }
}

function PrivateRoute({ children, ...rest }) {
  const user = useCurrentUser();
  const isLoggedIn = Boolean(user && user.user_id);
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (isLoggedIn) {
          return children;
        }
        return (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  ...ownProps,
});

const mapDispatchToProps = {
  registration_flow_get,
  update_registration_flow,
  set_registration_type,
  create_self_register_post,
  login_post,
  check_signup_post,
  self_reg_content_get,
  get_user_attributes_get,
  reset_registration_flow,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withService(["userService"])(SignupPage)),
);
